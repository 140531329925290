import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Visible } from "react-grid-system"
import Layout from "../components/home/layout"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getHomePath } from "../utils/path"
import { Box, Container } from "theme-ui"
import Footer from "../components/home/blocks/footer"
import LastEditions from "../components/home/blocks/lastEditions"
import MainEvent from "../components/home/blocks/mainEvent"
import OrganiserCollection from "../components/home/blocks/organiserCollection"
import TextComponent from "../components/home/blocks/textComponent"
import NewsletterSection from "../components/home/blocks/newsletterSection"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const IndexPage = ({ data: { page, site } }) => {
  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  const blockIds = []

  page.content.map((block, index) => {
    if (block.menuText)
      blockIds.push({
        id: block.id,
        title: block.menuText,
      })
  })

  return (
    <Layout
      locale={page.locale}
      i18nPaths={i18nPaths}
      theme="dark"
      pageSections={blockIds}
    > 
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container>
        {page.content.map(block => (
          <Box
            as="section"
            key={block.id}
            id={block.id}
            sx={{
              position: "relative",
              zIndex: 99,
            }}
          >
            {block.model.apiKey === "footer2023" && <Footer block={block} />}
            {block.model.apiKey === "last_editions_collection" && (
              <LastEditions block={block} />
            )}
            {block.model.apiKey === "main_event" && <MainEvent block={block} />}
            {block.model.apiKey === "organiser_collection" && (
              <OrganiserCollection block={block} />
            )}
            {block.model.apiKey === "text" && <TextComponent block={block} />}
            {block.model.apiKey === "newsletter_section" && (
              <NewsletterSection block={block} />
            )}
          </Box>
        ))}
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery($id: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      subtitle
      label
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsFooter2023 {
          id
          privacy {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          cookie {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          credits {
            ... on DatoCmsExternalLink {
              ...ExternalLinkDetails
            }
            ... on DatoCmsInternalLink {
              ...InternalLinkDetails
            }
          }
          legalInfo
          model {
            apiKey
          }
        }
        ... on DatoCmsOrganiserCollection {
          id
          menuText
          organisers {
            ... on DatoCmsOrganiser {
              id
              title
              description
              logo {
                url
                alt
              }
              link {
                url
                anchor
              }
              model {
                apiKey
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsMainEvent {
          id
          menuText
          event {
            ...InternalLinkDetails
            ...ExternalLinkDetails
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsText {
          id
          menuText
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsLastEditionsCollection {
          id
          menuText
          title
          editions {
            ... on DatoCmsOldEdition {
              title
              description
              year
              image {
                url
                alt
              }
              link {
                ...InternalLinkDetails
                ...ExternalLinkDetails
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsNewsletterSection {
          id
          menuText
          title
          description
          link {
            ...InternalLinkDetails
            ...ExternalLinkDetails
          }
          model {
            apiKey
          }
        }
      }
      seo {
        title
        description
      }
    }
  }

  fragment ExternalLinkDetails on DatoCmsExternalLink {
    id
    anchor
    url
    model {
      apiKey
    }
  }

  fragment InternalLinkDetails on DatoCmsInternalLink {
    id
    anchor
    locale
    model {
      apiKey
    }
    link {
      ... on DatoCmsCompassi {
        id
        description
        slug
        locale
        content {
          ... on DatoCmsCompassiHero {
            id
            title
            subtitle
            heroImage {
              url
              alt
            }
            model {
              apiKey
            }
          }
        }
        model {
          apiKey
        }
      }
      ... on DatoCmsHomePage2020 {
        id
        slug
        locale
        model {
          apiKey
        }
      }
      ... on DatoCmsPage {
        id
        slug
        root
        _allSlugLocales {
          value
          locale
        }
        locale
        model {
          apiKey
        }
      }
    }
  }
`
