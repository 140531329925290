import React from "react"
import { Box, Flex, Heading, Text } from "theme-ui"
import { i18nContext } from "../../../context/i18nContext"
import { MagicLink } from "../../../utils/magicLink"
import ArrowRight from "../../../images/arrow-right.inline.svg"

const NewsletterSection = ({ block }) => {
  console.log(block)
  const { title, description, link } = block
  return (
    <Box
      sx={{
        mb:[5,7],
        backgroundColor: "primary",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Box
              sx={{
                p: [3, 4],
                height: "400px",
              }}
            >
              <Flex
                sx={{
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Box>
                  <Heading
                    as="h2"
                    sx={{
                      fontSize: [4],
                      fontWeight: "500",
                      lineHeight: "28px",
                      color: "light",
                      mt: 0,
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </Heading>
                  <Box
                    sx={{
                      p: {
                        fontSize: [4, 4],
                        lineHeight: "28px",
                        fontWeight: "400",
                        color: "secondary",
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Box>
                <Box>
                  {console.log("link",link)}
                  <MagicLink item={link}>
                    <Flex
                      sx={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                      variant="arrowAnimation"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          sx={{
                            mr: 2,
                            fontWeight: "500",
                            fontSize: [3, 4],
                            color: "light",
                            lineHeight: "24px",
                          }}
                        >
                          {t.subscribeNewsletter}
                        </Text>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          "svg *":{
                            fill:"light"
                          }
                        }}
                      >
                        <ArrowRight />
                      </Box>
                    </Flex>
                  </MagicLink>
                </Box>
              </Flex>
            </Box>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default NewsletterSection
