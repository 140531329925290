import React from "react"
import { Box, Grid, Heading, Image, Text } from "theme-ui"
import { i18nContext } from "../../../context/i18nContext"
import { MagicLink } from "../../../utils/magicLink"

const LastEditions = ({ block }) => {
  const { title, editions } = block

  const getGridStructure = index => {
    switch (index) {
      case 0:
        return ["1", "1/2", "1/2", "1/3"]
        break
      case 1:
        return ["1", "2/2", "2/2", "3/4"]
        break
      case 2:
        return ["1", "1/2", "1/2", "4/5"]
        break
      case 3:
        return ["1", "2/2", "2/2", "1/2"]
        break
      case 4:
        return ["1", "1/2", "1/2", "2/3"]
        break
      case 5:
        return ["1", "2/2", "2/2", "3/5"]
        break
      case 6:
        return ["1", "1/2", "1/2", "1/2"]
        break
      case 7:
        return ["1", "2/2", "2/2", "2/4"]
        break
      case 8:
        return ["1", "1/2", "1/2", "4/5"]
        break
    }
  }

  return (
    <Box
      sx={{
        py: [5, 7],
        borderTop: "1px solid",
        borderColor: "borderLightGrey",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Heading
              as="h2"
              sx={{
                textTransform: "uppercase",
                mt: [0],
                mb: [6],
                color: "dark",
                fontWeight: "500",
                fontSize: [2, 4],
              }}
            >
              {title}
            </Heading>
            <Grid
              columns={["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
            >
              {editions.map((edition, index) => (
                <Box
                  sx={{
                    gridColumn: getGridStructure(index),
                  }}
                >
                  <MagicLink sx={{
                    display:"block",
                    h2:{
                      transition:"color .3s ease-in-out",
                    },
                    "&:hover" :{
                        "h2": {
                          transition:"color .3s ease-in-out",
                          color:"primary"
                        }
                    }
                  }} item={edition.link} target="_blank" >
                    <Box>
                      <Image src={edition.image.url} alt="edition.title" />
                    </Box>
                    <Box>
                      <Heading
                        as="h2"
                        sx={{
                          textTransform: "uppercase",
                          mt: [4],
                          mb: [2],
                          color: "dark",
                          fontWeight: "500",
                          fontSize: [2, 4],
                        }}
                      >
                        {edition.title}
                      </Heading>
                    </Box>
                    <Box
                      sx={{
                        color: "dark",
                        p: {
                          fontSize: [2, 4],
                          lineHeight: "28px",
                          color: "dark",
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: edition.description }}
                    />
                    <Box>
                      <Text
                        sx={{ color: "primary", mt: [2, 3], fontSize: [2, 4] }}
                      >{`${t.edition} ${edition.year}`}</Text>
                    </Box>
                  </MagicLink>
                </Box>
              ))}
            </Grid>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default LastEditions
