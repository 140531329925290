import React from "react"
import { Box, Container, Grid, Heading, Image, Flex, Text } from "theme-ui"
import { MagicLink } from "../../../utils/magicLink"
import { i18nContext } from "../../../context/i18nContext"
import { InboundLink } from "../link"
import { getCustomPage } from "../../../utils/path"
import ArrowRight from "../../../images/arrow-right.inline.svg"

const MainEvent = ({ block }) => {
  const { title, event } = block
  console.log(event.link)

  return (
    <Box sx={{ position: "relative", mb: 4 }}>
      <i18nContext.Consumer>
        {t => (
          <>
            {event.link.content &&
              event.link.content.map(block =>
                block.model && block.model.apiKey ? (
                  <Box
                    as="section"
                    key={block.id}
                    id={block.id}
                    sx={{
                      position: "relative",
                      zIndex: 99,
                    }}
                  >
                    
                    {block.model.apiKey === "compassi_hero" && (
                      <InboundLink
                      sx={{
                        color:"dark",
                        "&:hover":{
                          color:"dark"
                        }
                      }}
                      to={getCustomPage(event.link, event.locale)}
                    >
                      <Grid
                        columns={["1fr", "1fr", "1fr", "1fr 1fr"]}
                        gap={[0]}
                      >
                        <Box
                          sx={{
                            backgroundColor: "compassi",
                            py: [4, 6, 6, 12],

                            px: [4, 6],
                          }}
                        >
                          <Image
                            src={block.heroImage.url}
                            alt={block.heroImage.alt}
                          />
                        </Box>
                        <Box
                          sx={{
                            backgroundColor: "#FAD7D7",
                            height: "100%",
                            p: [3, 4],
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: [4],
                              strong: {
                                color: "#A81922",
                                em: {
                                  fontWeight: "400",
                                  fontStyle: "normal",
                                },
                              },
                              em: {
                                color: "#A81922",
                                fontWeight: "400",
                              },
                            }}
                            dangerouslySetInnerHTML={{
                              __html: event.link.description,
                            }}
                          />
                          <Box>
                            <InboundLink
                              to={getCustomPage(event.link, event.locale)}
                            >
                              <Flex
                                variant="arrowAnimation"
                                sx={{
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    sx={{
                                      mr: 2,
                                      fontWeight: "500",
                                      fontSize: [3, 4],
                                      color: "dark",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t.discoverMore}
                                  </Text>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ArrowRight />
                                </Box>
                              </Flex>
                            </InboundLink>
                          </Box>
                        </Box>
                      </Grid>
                      </InboundLink>
                    )}
                  </Box>
                ) : (
                  ""
                )
              )}
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default MainEvent
