import React from "react"
import { Box, Flex, Grid, Heading, Image, Text } from "theme-ui"
import { i18nContext } from "../../../context/i18nContext"
import { MagicLink } from "../../../utils/magicLink"
import ArrowRight from "../../../images/arrow-right.inline.svg"

const OrganiserCollection = ({ block }) => {
  const { organisers } = block

  return (
    <Box
      sx={{
        py: [5, 7],
        borderTop: "1px solid",
        borderColor: "borderLightGrey",
      }}
    >
      <i18nContext.Consumer>
        {t => (
          <>
            <Grid
              columns={["1fr", "1fr", "auto 1fr", "auto 1fr"]}
              gap={[5, 5, 6, 9]}
            >
              <Box>
                <Heading
                  as="h2"
                  sx={{
                    textTransform: "uppercase",
                    mt: [0],
                    mb: [2],
                    color: "dark",
                    fontWeight: "500",
                    fontSize: [2, 4],
                  }}
                >
                  {t.organizers}
                </Heading>
              </Box>
              <Box>
                {organisers.map((organiser, index) => (
                  <Box
                    sx={{
                      mb: index !== organisers.length - 1 && [4, 6],
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "425px",
                      }}
                    >
                      <Box
                        sx={{
                          pb: [4],
                          display: ["block", "block", "block", "none"],
                          img: {
                            maxWidth: ["50%", "273px"],
                          },
                        }}
                      >
                        <Image
                          src={organiser.logo.url}
                          alt={organiser.logo.alr}
                        />
                      </Box>
                      <Heading
                        as="h2"
                        sx={{
                          fontSize: [2, 4],
                          mb: [2],
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: "dark",
                          mt: 0,
                        }}
                      >
                        {organiser.title}
                      </Heading>

                      <Box
                        sx={{
                          p: {
                            fontSize: [1],
                            lineHeight: "20px",
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: organiser.description,
                        }}
                      />
                      <Box
                        sx={{
                          pt: [5],
                          pb: [5, 5, 0],
                        }}
                      >
                        <MagicLink item={organiser.link}>
                          <Flex
                            sx={{
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                            variant="arrowAnimation"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                sx={{
                                  mr: 2,
                                  fontWeight: "500",
                                  fontSize: [3, 4],
                                  color: "dark",
                                  lineHeight: "24px",
                                }}
                              >
                                {t.discoverMore}
                              </Text>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <ArrowRight />
                            </Box>
                          </Flex>
                        </MagicLink>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        pt:[4],
                        display: ["none", "none", "none", "block"],
                        img: {
                          maxWidth: ["50%", "273px"],
                        },
                      }}
                    >
                      <Image
                        src={organiser.logo.url}
                        alt={organiser.logo.alr}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </>
        )}
      </i18nContext.Consumer>
    </Box>
  )
}

export default OrganiserCollection
