import React from "react"
import { Box, Container } from "theme-ui"

const TextComponent = ({ block }) => {
  const { description } = block
  return (
    <Box
      sx={{
        py: [5, 7],
        borderTop: "1px solid",
        borderColor: "borderLightGrey",
        "h2":{
          textTransform: "uppercase",
          mt: [0],
          color: "dark",
          fontWeight: "500",
          fontSize: [2, 4],
          
        },
        p: {
          fontSize: [2, 5],
        },
      }}
    >
      <Box dangerouslySetInnerHTML={{ __html: description }} />
    </Box>
  )
}

export default TextComponent
